
// @ts-nocheck


export const localeCodes =  [
  "en",
  "pl"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js" /* webpackChunkName: "locale__opt_apps_web_locales_en_js" */), cache: true }],
  "pl": [{ key: "../locales/pl.js", load: () => import("../locales/pl.js" /* webpackChunkName: "locale__opt_apps_web_locales_pl_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": true,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "flag": "gb",
      "name": "English",
      "language": "en-GB",
      "files": [
        "/opt/apps/web/locales/en.js"
      ]
    },
    {
      "code": "pl",
      "flag": "pl",
      "name": "Polski",
      "language": "pl-PL",
      "files": [
        "/opt/apps/web/locales/pl.js"
      ]
    }
  ],
  "defaultLocale": "pl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://nextpark.dev",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "parkings/airports": {
      "pl": "/parkingi-lotniskowe",
      "en": "/airport-parkings"
    },
    "parkings/stations": {
      "pl": "/parking-dworzec",
      "en": "/parking-station"
    },
    "contact": {
      "pl": "/kontakt",
      "en": "/contact"
    },
    "others/howtoselect": {
      "pl": "/jak-wybrac-parking-lotniskowy",
      "en": "/how-to-choose-place-parking"
    },
    "others/whywe": {
      "pl": "/dlaczego-nextpark",
      "en": "/why-nextpark"
    },
    "others/extras-airclaim": {
      "pl": "/odszkodowania-lotnicze",
      "en": "/airline-compensation"
    },
    "others/extras-travelinsurance": {
      "pl": "/ubezpieczenia-podrozne",
      "en": "/travel-insurance"
    },
    "others/extras-carrentals": {
      "pl": "/wynajem-pojazdow",
      "en": "/car-rentals"
    },
    "others/rules-terms": {
      "pl": "/regulamin",
      "en": "/rules"
    },
    "others/rules-privacy": {
      "pl": "/polityka-prywatnosci",
      "en": "/privacy-policy"
    },
    "others/howitworks": {
      "pl": "/jak-to-dziala",
      "en": "/how-it-works"
    },
    "faq/index": {
      "pl": "/pytania-parking-lotniskowy",
      "en": "/airport-carpark-questions"
    },
    "faq/[category]": {
      "pl": "/pytania-parking-lotniskowy/[category]",
      "en": "/airport-carpark-questions/[category]"
    },
    "bookings/manage": {
      "pl": "/zarzadzaj-rezerwacja",
      "en": "/manage-booking"
    },
    "bookings/booking": {
      "pl": "/zarzadzaj-rezerwacja/moja-rezerwacja",
      "en": "/manage-booking/my-booking"
    },
    "confirmation": {
      "pl": "/potwierdzenie",
      "en": "/confirmation"
    },
    "[place]/[parking]/reservation": {
      "pl": "/[place]/[parking]/rezerwacja",
      "en": "/[place]/[parking]/reservation"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "flag": "gb",
    "name": "English",
    "language": "en-GB",
    "files": [
      {
        "path": "/opt/apps/web/locales/en.js"
      }
    ]
  },
  {
    "code": "pl",
    "flag": "pl",
    "name": "Polski",
    "language": "pl-PL",
    "files": [
      {
        "path": "/opt/apps/web/locales/pl.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
